import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes, Redirect, useRouteMatch } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
// import Home from '../views/home';
// import AboutUs from '../views/about-us';
// import ContactUs from '../views/contact-us';
import Blog from '../views/blog';
import BlogDetails1 from '../views/What-is-WhatsApp-Business-API-Integration';
import BlogDetails2 from '../views/How-Chatbots-Revolutionize-Lead-Generation';
import BlogDetails3 from '../views/WhatsApp-Chatbot-for-Education-Institutions';

// import ECommerce from '../views/chatbot-for-ecommerce';
// import Features from '../views/features';
import HealthCare from '../views/ai-healthcare-chatbot';
// import TermsCondition from '../views/terms-condition';
// import PrivacyPolicy from '../views/privacy-policy';
import PageNotFound from '../views/pagenotfound';
import ThanksMsg from '../components/thanks';
// import Educations from '../views/whatsapp-education-chatbot';
// import Finance from '../views/finance-chatbot';
// import Travel from '../views/travel-chatbot';
// import Hotel from '../views/chatbot-for-hotels';
import { lazy, Suspense } from 'react';

const Home = lazy(() => import('../views/home'));

const AboutUs = lazy(() => import('../views/about-us'));
const ContactUs = lazy(() => import('../views/contact-us'));
const PrivacyPolicy  = lazy(() => import('../views/privacy-policy'));
const ECommerce = lazy(() => import('../views/chatbot-for-ecommerce'));
const Features = lazy(() => import('../views/features'));
const TermsCondition = lazy(() => import('../views/terms-condition'));
const Finance = lazy(() => import('../views/finance-chatbot'));
const Travel = lazy(() => import('../views/travel-chatbot'));
const Hotel = lazy(() => import('../views/chatbot-for-hotels'));
const Educations = lazy(() => import('../views/whatsapp-education-chatbot'));
const TeamInbox = lazy(() => import('../views/whatsapp-team-inbox'));

const BaseLayouts = () => {

    return (
        <>
            <React.Fragment>
                <BrowserRouter>
                <Suspense fallback={<h1></h1>}>
                    <Routes>
                    
                        <Route path='/' element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/about-us' element={<AboutUs />} />
                        <Route path='/contact-us' element={<ContactUs />} />
                        <Route path='/blog' element={<Blog />} />
                        <Route path='/What-is-WhatsApp-Business-API-Integration' element={<BlogDetails1 />} />
                        <Route path='/How-Chatbots-Revolutionize-Lead-Generation' element={<BlogDetails2 />} />
                        <Route path='/WhatsApp-Chatbot-for-Education-Institutions' element={<BlogDetails3 />} />
                        <Route path='/chatbot-for-ecommerce' element={<ECommerce />} />
                        <Route path='/features' element={<Features />} />
                        <Route path='/ai-healthcare-chatbot' element={<HealthCare />} />
                        <Route path='/whatsapp-education-chatbot' element={<Educations/>}/>
                        <Route path='/terms-condition' element={<TermsCondition/>}/>
                        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
                        <Route path='/pagenotfound' element={<PageNotFound/>}/>
                        <Route path='/thanks' element={<ThanksMsg/>}/>
                        <Route path='/finance-chatbot' element={<Finance/>}/>
                        <Route path='/travel-chatbot' element={<Travel/>}/>
                        <Route path='/chatbot-for-hotels' element={<Hotel/>}/>
                        <Route path='/whatsapp-team-inbox' element={<TeamInbox/>}/>
                        <Route path='*' element={<Navigate to="pagenotfound" />}/>

                        <Route path='/ecommerce-chatbot' element={<Navigate to="/chatbot-for-ecommerce" />}/>
                        <Route path='/whatsapp-chatbot-healthcare' element={<Navigate to="/ai-healthcare-chatbot" />}/>
                        <Route path='/Chatbots-for-education' element={<Navigate to="/whatsapp-education-chatbot" />}/>
                        <Route path='/finance-ai-chatbot' element={<Navigate to="/finance-chatbot" />}/>

                    </Routes>
                  </Suspense>
                </BrowserRouter>
            </React.Fragment>
        </>
    );
}
export default BaseLayouts;